import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { palette } from "../../_variables"
import { respondTo } from "../../_respondTo"

// used to make child routes show as active
const partlyActive = className => ({ isPartiallyCurrent }) => ({
  className: className + (isPartiallyCurrent ? ` active` : ``),
})

const PartlyActiveLink = ({ className, ...rest }) => (
  <Link getProps={partlyActive(className)} {...rest} />
)
//end

const NavItem = styled(PartlyActiveLink)`
  text-decoration: none;
  color: ${palette.primaryContrastLight};
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: all 200ms ease-in;
  position: relative;
  z-index: 6;
  font-size: 1.1rem;
  padding: 0.1em 1em;
  font-size: 2rem;
  border-radius: 0.5em;

  :hover {
    color: ${palette.primaryContrastStong};

    ::after {
      width: 100%;
    }
  }

  &.active {
    color: ${palette.primaryContrastStong};

    ${respondTo.md`
        height:8vh;
        background-color:${palette.primary};
        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0 100%);
    `}
  }

  ${respondTo.md`
    flex:1;
    font-size: 1rem;
    border-radius:0;
    `}

  &.pfp {
    background-color: ${palette.secondary};
    :hover {
      color: ${palette.primaryContrastStong};
      background-color: ${palette.secondaryLight};

      text-decoration: none;
    }
  }
  ${respondTo.md`

 `}

  &.self-serve {
    background-color: ${palette.green};

    :hover {
      color: ${palette.primaryContrastStong};
      background-color: ${palette.greenLight};
      text-decoration: none;
    }

    ${respondTo.md`

  `}
  }
`

// log the user clicking the link to take them to pfp
// uses the Tinybird script that is loaded on each page via the Layout component
function logPfpClick(){
  Tinybird.trackEvent('click_pfp_link', {
    href: window.location.href
    //timestamp and session-id auto added by tinybird
  });
}


const Links = () => {
  return (
    <>
      <NavItem to="/about" activeClassName="active">
        About
      </NavItem> 
      <NavItem to="/services-for-clients" activeClassName="active">
        Services
      </NavItem>
      <NavItem to="/finding-true-wealth" activeClassName="active">
        Blog
      </NavItem>
      <NavItem to="/contact" activeClassName="active">
        Contact
      </NavItem>
      <NavItem
        to="/self-serve/"
        activeClassName="active"
        className="self-serve"
        
      >
        Self Serve
      </NavItem>
      <NavItem as="a" href="https://lsnifa.gb.pfp.net/" className="pfp" onClick={logPfpClick}>
        Client Portal
      </NavItem>
    </>
  )
}

export default Links
