import React, { useState } from "react"
import styled from "styled-components"
import Links from "./links"
import { Logo, Icon } from "./logo"

import { palette } from "../../_variables"
import { respondTo } from "../../_respondTo"

const Navigation = styled.nav`
  position: fixed;
  top: 0;
  left:0;
  right:0;

  z-index: 2;
  height: 5vh;

  min-height:35px; // to accomodate burger


  display: flex;
  justify-content: flex-end;
  align-self: center;
  
  background-color: ${palette.primary};

  ${respondTo.md`
    background-color: transparent;
  `}

`

const Toggle = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;
  z-index:99;

  ${respondTo.md`
    display: none;
  `}
`

// this styled component contains a style to make the nav work in Internet Explorer
const NavboxIE = styled.div`
  display: flex;
  flex:1; //added for IE, otherwise nav links collapse on each other
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
  position: fixed;
  width: 100%;
  height:85%;
  top: ${props => (props.open ? "-100%" : "5vh")};

  padding: 4vh 0 15vh 0;
  background-color: ${palette.primary};
  transition: all 0.3s ease-in;
  
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0 100%);

  ${respondTo.md`
    align-items: stretch;
    font-size:1em;
    top:0;
    width: auto;
    padding: 0 30px 0 0;
    flex-direction: row;
    justify-content: flex-end;
    position: static;
    clip-path:none;
    border-radius: 0 0 0 0.5em;
  `}
`
const Navbox = styled(NavboxIE)`
  @supports(display:grid){
    flex:none; // IE doesn't support "@supports", so this won't be applied for IE
  }

`

const Hamburger = styled.div`
  right:0;//added - doesn't work
  background-color: ${palette.primaryContrast};
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: ${palette.primaryContrast};
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }
  ::before {
    transform: ${props =>
    props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }
  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <Navigation>
      <Logo />
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <Icon />
          <Links />
        </Navbox>
      ) : (
          <Navbox open>
            <Links />
          </Navbox>
        )}
    </Navigation>
  )
}

export default Navbar