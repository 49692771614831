import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import lsnifaLogo from "../../images/lsnifa-logo-ribbon-only.svg"
import lsnifaIcon from "../../images/lsnifa-icon.svg"
import { respondTo } from "../../_respondTo"

const LogoWrap = styled.div`

    display:none;
    margin:0;

  ${respondTo.md`
    display:block;
    margin: 0 auto 0 30px;
    width:6vh;
  `}
  
`

const IconWrap = styled.div`

    display:block;

  ${respondTo.md`
    display:none;
  `}
  
`

export const Logo = () => {

    return (
        <LogoWrap as={Link} to="/" >
            <img src={lsnifaLogo} alt="LSNIFA logo" style={{ display: "block" }}></img>
        </LogoWrap>
    )
}


export const Icon = () => {

    return (
        <IconWrap as={Link} to="/">
            <img src={lsnifaIcon} alt="LSNIFA logo"></img>
        </IconWrap>
    )

}


