/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import "fontsource-open-sans/300.css"
import "fontsource-open-sans/400.css"
import "fontsource-open-sans/600.css"
import "./layout.css"

import Navbar from "./navbar/navbar"
import Footer from "./footer"

/*
if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}
*/

const Layout = ({ children }) => {
  /* const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)*/

  return (
    <>
      <Helmet>
        <script
          defer
          src="https://unpkg.com/@tinybirdco/flock.js"
          data-host="https://api.tinybird.co"
          data-token="p.eyJ1IjogImQ2NWY2MGQ1LWIwM2EtNDkzOS1hMTg4LTU2MGYwNTkyMjNlMiIsICJpZCI6ICJiZGUyOGM2NS0wOTI2LTQ2MDktOTFiZi1jZDE2N2YxM2I1ZjkifQ.8m5vDarlvGI91ewWRHSRcxJXUJa7vuptpb7WtSu0PSA"
        ></script>
      </Helmet>
      <Navbar></Navbar>

      <main>{children}</main>
      <Footer></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
