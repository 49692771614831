
export const breakpoints = {
    xs: "480px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
}

export const palette = {
    primary: "#002a6c",
    primaryContrast: "#f5f5f5",
    primaryContrastStong: "#fff",
    primaryContrastLight: "#c7c7c7",

    secondary: "#2839bd",
    secondaryLight: "#2e7ecf",

    green: "#245d01",
    greenLight: "#2a9400",
    test: "#2a9400"
}